import React, { useState } from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import bottomLogo from './images/img_bottom@2x.png'; // 導入返回按鈕圖像
import backButtonImg from './images/btn_back@2x.png'; // 導入返回按鈕圖像

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px; /* 左右邊界 */
`;

const WhiteText = styled.p`
    color: white;
    text-align: left;
`;
const backButtonStyle = {
    position: 'absolute',
    top: '20px',
    left: '20px',
    // zIndex: '9999',
    width: '30px',
    height: '30px',
    marginBottom: '100px'
};
const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  height: 20px;
  color: #fff;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterText = styled.div`
  text-align: left;
  color: #fff;
  font-size: 10px;
  margin-left: 10px;
`;

const FooterImage = styled.img`
  max-width: 100%;
  height: 20px;
  margin-right: 10px;
`;
const PageAddMyGames = () => {
    const [url, setUrl] = useState('');
    const [country, setCountry] = useState('');
    const [appId, setAppId] = useState('');
    const [appData, setAppData] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const handleFetchClick = async () => {
        // 解析網址獲取 country 和 appId
        const urlParts = url.split('/');
        const countryValue = urlParts[urlParts.indexOf('app') - 1];
        const appIdValue = urlParts.pop().replace('id', '');

        setCountry(countryValue);
        setAppId(appIdValue);

        // 調用 fetchAppData 函數獲取 App 數據
        await fetchAppData(countryValue, appIdValue);
    };

    const handleAddButtonClick = async () => {
        // 處理添加遊戲按鈕點擊事件
        if (appData) {
            // 構造要保存的對象
            const newData = {
                title: appData.title,
                imageUrl: appData.icon,
                appStoreLink: url
            };

            // 從 localStorage 中獲取現有數據
            const existingData = localStorage.getItem('myGames');
            let dataArray = [];

            // 如果已存在數據，將其轉換為數組
            if (existingData) {
                dataArray = JSON.parse(existingData);
            }

            // 將新數據添加到數組中
            dataArray.push(newData);

            // 將更新後的數組保存到 localStorage 中
            localStorage.setItem('myGames', JSON.stringify(dataArray));

            setShowSuccessMessage(true);

            setTimeout(() => {
                window.history.back();
            }, 1000);
        }
    };

    const fetchAppData = async (country, id) => {
        try {
            const response = await fetch(`https://app-store2.p.rapidapi.com/app/${id}?country=${country}`, {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Key': '545b9fa237mshe7dc87ecdbe84a8p155aaajsn6fe4255c68a7',
                    'X-RapidAPI-Host': 'app-store2.p.rapidapi.com'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setAppData(data);
            } else {
                throw new Error('Failed to fetch App data');
            }
        } catch (error) {
            console.error('Error fetching App data:', error);
        }
    };
    const Input = styled.input`
    width: 80%;
`;
    const systemLanguage = localStorage.getItem('language');
    return (

        <>
            <Link to="/">
                <img src={backButtonImg} alt="Back" style={backButtonStyle} />
            </Link>
            <PageContainer>

                <h1><WhiteText>Add Game</WhiteText></h1>
                <Input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="Enter App URL"

                />
                <br></br>
                <button onClick={handleFetchClick}>Fetch Data</button>

                {/* 顯示解析後的數據 */}
                {appData && (
                    <div>
                        <h2><WhiteText>App Data:</WhiteText></h2>

                        <WhiteText>Country: {country}</WhiteText>
                        <WhiteText>App ID: {appId}</WhiteText>
                        <WhiteText>App Name: {appData.title}</WhiteText>
                        <WhiteText>Developer: {appData.developer}</WhiteText>
                        <img
                            src={appData.icon}
                            alt={appData.title}
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                        />
                        {/* 其他需要顯示的數據 */}
                    </div>
                )}
                <br></br>
                {showSuccessMessage && <WhiteText> <p>Add successful!</p></WhiteText>}
                <br></br>
                <button onClick={handleAddButtonClick}>Add To My Game</button>
            </PageContainer>
            );
            <FooterContainer>
                <FooterText>Region: {systemLanguage}</FooterText>
                <FooterImage src={bottomLogo} alt="Footer Image" />
            </FooterContainer>
        </>
    );
}


export default PageAddMyGames;
