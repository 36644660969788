import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

import addFavoriteButtonImg from './images/btn_favorite@2x.png'; // 導入返回按鈕圖像
import removeButtonImg from './images/btn_favorite_focus@2x.png'; // 導入返回按鈕圖像
import bottomLogo from './images/img_bottom@2x.png'; // 導入返回按鈕圖像
const screenWidth = window.innerWidth; // 獲取屏幕寬度
const imageWidth = screenWidth * 0.3; // 計算圖像寬度為屏幕寬度的50%
const imageHeight = imageWidth; // 讓高度等於寬度

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%; /* 螢幕寬度的 80% */
  height:88vh;
  margin: 0 auto; /* 水平置中 */
  overflow-y: auto; /* 添加上下滚动 */
`;

const ImageItem = styled.div`
  display: flex;
  align-items: flex-start; /* 将图像项向上对齐 */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`;

const Image = styled.img`
  width: 124px;
  height: 124px;
  objectFit: cover;
  paddingLeft: 2px';
  paddingRight: 2px;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  margin-top: -10px; /* 調整文字的上邊距 */
`;
const Title = styled.h4`
  margin: 0;
  padding-bottom: 0px;
  color: #fff;
`;

const SubTitle = styled.div`
  margin: 0;
  color: #fff;
  font-size: 14px; 
`;
const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  height: 20px;
  color: #fff;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterText = styled.div`
  text-align: left;
  color: #fff;
  font-size: 10px;
  margin-left: 10px;
`;

const FooterImage = styled.img`
  max-width: 100%;
  height: 20px;
  margin-right: 10px;
`;

function TwoColumnAllGames() {
    const [images, setImages] = useState([]);
    const [gameData, setGameData] = useState(null);
    const [savedFavoriteGames, setSavedFavoriteGames] = useState('');
    const systemLanguage = localStorage.getItem('language');
    const handleImageClick = (appStoreLink, gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面

        // var savedFavoriteGames = localStorage.getItem('favoriteGames');

        // if ((savedFavoriteGames == null) || (savedFavoriteGames == '')) {
        //     localStorage.setItem('favoriteGames', gameId);
        // } else {
        //     // localStorage.setItem('favoriteGames', savedFavoriteGames + "," + gameId);
        //     var favoriteGamesArray = savedFavoriteGames.split(',');

        //     // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
        //     if (!favoriteGamesArray.includes(gameId)) {
        //         // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
        //         favoriteGamesArray.push(gameId);
        //         // 更新本地存儲
        //         localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));

        //     } else {
        //         //如果存在則刪除

        //         favoriteGamesArray = favoriteGamesArray.filter(item => item !== gameId);
        //         // 更新本地存儲
        //         localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
        //     }
        // }
        // savedFavoriteGames = localStorage.getItem('favoriteGames');

        // setSavedFavoriteGames(savedFavoriteGames);
        // 如果您使用原生的網頁導航，可以使用 window.location.href
        window.location.href = appStoreLink;
    };
    const addFavorite = (gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面
        // history.push(appStoreLink);
        // localStorage.setItem('favoriteGames', '');

        var savedFavoriteGames = localStorage.getItem('favoriteGames');
        var favoriteGamesArray;

        if (savedFavoriteGames === null || savedFavoriteGames === undefined || savedFavoriteGames === '') {
            // localStorage 中沒有存儲 'favoriteGames' 數據
            // 進行相應的處理
            favoriteGamesArray = [gameId]; // 初始化 favoriteGamesArray
            // 更新本地存儲
            localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));


        } else {
            // localStorage 中已經存儲了 'favoriteGames' 數據
            favoriteGamesArray = savedFavoriteGames.split(',');
            // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
            if (!favoriteGamesArray.includes(gameId)) {
                // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
                favoriteGamesArray.push(gameId);
                // 更新本地存儲
                localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
            }

        }

        savedFavoriteGames = localStorage.getItem('favoriteGames');
        setSavedFavoriteGames(savedFavoriteGames);

        // window.location.reload();
    };
    const removeFavorite = (gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面
        // history.push(appStoreLink);
        // localStorage.setItem('favoriteGames', '');
        var savedFavoriteGames = localStorage.getItem('favoriteGames');


        var favoriteGamesArray = savedFavoriteGames.split(',');

        // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
        if (!favoriteGamesArray.includes(gameId)) {
            // // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
            // favoriteGamesArray.push(gameId);
            // // 更新本地存儲
            // localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));

        } else {
            //如果存在則刪除

            favoriteGamesArray = favoriteGamesArray.filter(item => item !== gameId);
            // 更新本地存儲
            localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
        }


        savedFavoriteGames = localStorage.getItem('favoriteGames');

        setSavedFavoriteGames(savedFavoriteGames);

        // window.location.reload();

    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const systemLanguage = localStorage.getItem('language');

                var response = await fetch("./data/gameData_" + systemLanguage + ".json");

                let data; // 在 try 塊外部聲明一個變量
                try {
                    data = await response.json();
                    // 在這裡處理獲取到的 JSON 格式數據
                    console.log("成功獲取到 JSON 格式的數據:", data);
                } catch (error) {
                    // JSON 格式轉換失敗，處理錯誤情況
                    response = await fetch("./data/gameData_en-US.json");
                    data = await response.json();
                    console.error("獲取 JSON 格式數據失敗:", error);
                }

                const editorsChoose = data.GamingCaseCompatible;
                const extractedImages = editorsChoose.map(item => {
                    // 檢查遊戲是否在 favoriteGames 中
                    const isFavorite = savedFavoriteGames !== null && savedFavoriteGames.split(',').includes(item.id);
                    return {
                        bigImageUrl: item.bigImageUrl,
                        imageUrl: item.imageUrl,
                        title: item.title, // 遊戲名稱
                        subTitle: item.subTitle,
                        description: item.description,
                        appStoreLink: item.appStoreLink, // App Store 鏈接
                        gameId: item.id, // 遊戲 ID
                        isFavorite: isFavorite // 遊戲是否收藏
                    };
                });
                setImages(extractedImages);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, [savedFavoriteGames]);
    useEffect(() => {
        const savedFavoriteGames = localStorage.getItem('favoriteGames');
        if (savedFavoriteGames) {
            setSavedFavoriteGames(savedFavoriteGames);
        }
    }, [savedFavoriteGames]);
    const [containerHeight, setContainerHeight] = useState(0); // 初始化容器高度為0
    useEffect(() => {
        // 根據圖片數量動態計算容器的最小高度
        const calculateContainerHeight = () => {
            const numberOfImages = Math.min(images.length, 4);
            // const minHeightPerImage = 124; // 假設每張圖片的最小高度為150px
            const calculatedHeight = numberOfImages * 22; // 計算總高度
            setContainerHeight(calculatedHeight); // 更新容器高度
        };

        calculateContainerHeight(); // 調用計算容器高度的函數
    }, [images]); // 當圖片數量發生變化時重新計算容器高度
    return (
        <>
            <Container style={{ height: `${containerHeight}vh` }}>
                {images.map((image, index) => (
                    <ImageItem key={index}>
                        {/* <Link to={{
                        pathname: '/gameDetails',
                    }}
                        state={{
                            gameDetail: {
                                id: image.gameId,
                                title: image.title,
                                bigImageUrl: image.bigImageUrl,
                                description: image.description,
                                appStoreLink: image.appStoreLink
                            }
                        }}>
                        <Image src={image.imageUrl} alt={`Slide ${index}`} />
                    </Link> */}
                        <Image src={image.imageUrl}
                            alt={`Slide ${index}`}
                            onClick={() => handleImageClick(image.appStoreLink, image.gameId)}
                            // style={{ width: `${imageWidth}px`, height: `${imageHeight}px` }} // 設置圖像寬度樣式
                            style={{
                                width: `${imageWidth}px`, height: `${imageHeight}px`, objectFit: 'cover', paddingLeft: '0px', paddingRight: '0px', borderRadius: '24px',
                                boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.6)'
                            }}
                        />
                        {image.isFavorite ? ( // 如果 image.isFavorite 為 true
                            <img
                                src={removeButtonImg} // 另一張圖片的路徑
                                alt="removeFavorite" // 圖片的替代文本
                                style={{
                                    position: 'relative',
                                    top: '0px',
                                    left: '-30px',
                                    width: '30px', // 調整圖片寬度
                                    height: '30px', // 調整圖片寬度
                                    zIndex: '1',
                                }}
                                onClick={() => removeFavorite(image.gameId)} // 點擊事件處理函數
                            />
                        ) : ( // 如果 image.isFavorite 為 false
                            <img
                                src={addFavoriteButtonImg} // 添加可點擊的圖片
                                alt="addFavorite" // 圖片的替代文本
                                style={{
                                    position: 'relative',
                                    top: '0px',
                                    left: '-30px',
                                    width: '30px', // 調整圖片寬度
                                    height: '30px', // 調整圖片寬度
                                    cursor: 'pointer', // 添加手型光標效果
                                    zIndex: '1',
                                }}
                                onClick={() => addFavorite(image.gameId)} // 點擊事件處理函數
                            />

                        )}
                        <TextContainer>
                            {/* <div style={{ textAlign: 'left', width: '100%', color: '#fff' }}><h2>{image.title}</h2></div>
                        <div style={{ textAlign: 'left', width: '100%', color: '#fff', fontWeight: 'normal' }}>{image.subTitle}</div> */}
                            <Title>{image.title}</Title>
                            <SubTitle>{image.subTitle}</SubTitle>
                        </TextContainer>

                    </ImageItem>
                ))}

            </Container>
            <FooterContainer>
                <FooterText>Region: {systemLanguage}</FooterText>
                <FooterImage src={bottomLogo} alt="Footer Image" />
            </FooterContainer>
        </>

    );
}

export default TwoColumnAllGames;
