// import logo from './logo.svg';
// import './App.css';
// import { HashRouter, Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from "./MainPage";
import GameDetails from "./gameDetails";
import PageAllGames from "./pageAllGames";
import PageAllFavorites from './pageAllFavorites';
import AddMyGame from './pageAddMyGames';


function App() {
  return (
    // <Router basename="js">
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/gameDetails" element={<GameDetails />} />
        <Route path="/allGames" element={<PageAllGames />} />
        <Route path="/allFavorites" element={<PageAllFavorites />} />
        <Route path="/addMyGame" element={<AddMyGame />} />
        {/* <Router basename="/js"></Router> */}
        {/* 定義其他路由 */}
      </Routes>
    </Router>
  );

}

export default App;
