import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import removeMyGameImg from './images/btn_delete@2x.png'; // 導入返回按鈕圖像

function SliderMyGames() {
    const [images, setImages] = useState([]);
    const [savedMyGames, setSavedMyGames] = useState('');

    useEffect(() => {
        console.log("111111111111");

        const savedData = localStorage.getItem('myGames');
        if (savedData) {
            const data = JSON.parse(savedData);
            setImages(data);
        }
    }, [savedMyGames]);

    const orientation = window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal';
    const slidesToShow = orientation === 'vertical' ? Math.min(3, images.length) : Math.min(5, images.length);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow + 1,
        // slidesToShow: images.length < 3 ? images.length : 3,
        // slidesToScroll: images.length < 3 ? images.length : 3,
        variableWidth: true,
        arrows: 0
    };

    const removeFromMyGame = (index) => {
        // 獲取當前保存在 localStorage 中的 favoriteGames
        var savedMyGames = localStorage.getItem('myGames');
        var myGamesArray = JSON.parse(savedMyGames);
        var numberOfGames = myGamesArray.length;

        // 將字符串格式的 savedFavoriteGames 轉換為數組
        // let favoriteGamesArray = savedMyGames ? savedMyGames.split(',') : [];
        // console.log("index:" + index + ",length:" + numberOfGames);
        // console.log(savedMyGames);

        // 檢查索引是否在合法範圍內
        if (index >= 0 && index < numberOfGames) {
            // 從數組中移除指定索引處的元素
            myGamesArray.splice(index, 1);

            // 將更新後的數組轉換為字符串，並保存到 localStorage 中
            localStorage.setItem('myGames', myGamesArray.join(','));

            // 更新組件狀態或重新加載頁面等操作
            // setSavedMyGames(localStorage.getItem('myGames'));
            setImages(myGamesArray);


        } else {
            console.error('Invalid index:', index);
        }
    };
    const handleImageClick = (appStoreLink) => {

        window.location.href = appStoreLink;
    };
    return (
        <div className="slider-container" style={{ width: '100%', margin: '8px' }}>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                        <img
                            src={image.imageUrl}
                            alt={`Slide ${index}`}
                            onClick={() => handleImageClick(image.appStoreLink)}
                            // style={{
                            //     width: '106px', height: '106px', objectFit: 'cover', paddingLeft: '2px', paddingRight: '2px', borderRadius: '10px',
                            //     boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.6)'
                            // }}
                            style={{
                                width: '106px', height: '106px', objectFit: 'cover', margin: '4px', borderRadius: '24px',
                                boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.6)'
                            }}
                        />
                        <img
                            src={removeMyGameImg}
                            alt="Remove From MyGames"
                            style={{
                                position: 'relative',
                                top: '-110px',
                                left: '81px',
                                width: '30px',
                                height: '30px',
                                cursor: 'pointer',
                                zIndex: '2',
                            }}
                            onClick={() => removeFromMyGame(index)}
                        // onClick={() => handleImageClick(image.appStoreLink)}
                        />
                        <div style={{ textAlign: 'center', width: '106px', height: '20px', color: '#fff', fontSize: '14px', marginTop: '-26px' }}>{image.title}</div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default SliderMyGames;
