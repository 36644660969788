// import React from 'react';
import React, { useState, useEffect } from "react";
import SliderSimple from './SliderSimple';
import SliderMultiple from './SliderMultiple';
import SliderFavorite from './SliderFavorite';
import SliderMyGames from './SliderMyGames';

import { Link } from 'react-router-dom';
// import './BackgroundImage.css'; // 導入包含背景圖片樣式的 CSS 文件
import bottomLogo from './images/img_bottom@2x.png'; // 導入返回按鈕圖像


const MainPage = () => {
  // const StyleSheet = {
  //   width: "100vw",
  //   height: "100vh",
  //   backgroundColor: "#000000",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   flexDirection: "column"
  // }


  const userLanguage = navigator.language || navigator.userLanguage;
  localStorage.setItem('language', userLanguage);

  return (
    <div style={{ overflowY: 'auto', height: '100%' }}> {/* 添加滾動樣式 */}

      <div style={{ marginTop: '14px', marginBottom: '20px' }}>
        <h4 style={{ marginLeft: '22px', width: '94%', margin: '0 auto', marginBottom: '12px', color: '#fff' }}>Featured</h4>
        <SliderSimple />
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h4 style={{ marginLeft: '22px', width: '94%', margin: '0 auto', marginBottom: '12px', color: '#fff' }}>
          Gaming Case Compatible
          <Link to={`/allGames`} style={{ float: 'right', marginRight: '0px', color: 'white', fontSize: '12px' }}>
            SHOW ALL
          </Link>
        </h4>
        <SliderMultiple />
      </div >

      <div style={{ marginBottom: '30px' }}>
        {/* <h3 style={{ marginLeft: '22px', width: '94%', margin: '0 auto', marginBottom: '6px', color: '#fff' }}>Favorites</h3> */}
        <h4 style={{ marginLeft: '22px', width: '94%', margin: '0 auto', marginBottom: '12px', color: '#fff' }}>
          Favorites
          <Link to={`/allFavorites`} style={{ float: 'right', marginRight: '0px', color: 'white', fontSize: '12px' }}>
            SHOW ALL
          </Link>
        </h4>
        <SliderFavorite />
      </div>

      <div style={{ marginBottom: '30px' }}>
        {/* <h3 style={{ marginLeft: '22px', width: '94%', margin: '0 auto', marginBottom: '6px', color: '#fff' }}>Favorites</h3> */}
        <h4 style={{ marginLeft: '22px', width: '94%', margin: '0 auto', marginBottom: '12px', color: '#fff' }}>
          My Games
          <Link to={`/addMyGame`} style={{ float: 'right', marginRight: '0px', color: 'white', fontSize: '12px' }}>
            Add Game
          </Link>
        </h4>
        <SliderMyGames />
      </div>
      {/* 底部内容使用固定定位 */}
      <div style={{
        backgroundColor: '#000',
        height: '20px',
        color: '#fff',
        padding: '2px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 999 /* 确保底部内容位于最顶层 */
      }}>
        <div style={{ textAlign: 'left', color: '#fff', fontSize: '10px', marginLeft: '10px' }}>
          <p>Region : {userLanguage}</p>
        </div>

        <div style={{ textAlign: 'right', marginRight: '0px' }}>
          <img src={bottomLogo} alt="Footer Image" style={{ maxWidth: '100%', height: '20px' }} />
        </div>
      </div>


    </div >



  )
}

export default MainPage;

