import React from 'react';
import { Link } from 'react-router-dom';
import TwoColumnForFavorites from './TwoColumnForFavorites';
import backButtonImg from './images/btn_back@2x.png'; // 導入返回按鈕圖像

const PageAllFavorites = () => {
  const StyleSheet = {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#08D9D6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
  const backButtonStyle = {
    position: 'absolute',
    top: '20px',
    left: '20px',
    // zIndex: '9999',
    width: '30px',
    height: '30px',
    marginBottom: '100px'
  };

  const spacerStyle = {
    height: '60px', // 設置間距的大小
  };

  return (
    <div style={{ marginBottom: '0px', position: 'relative' }}>
      <Link to="/">
        <img src={backButtonImg} alt="Back" style={backButtonStyle} />
      </Link>
      {/* <h3 style={{ width: '60%', margin: '0 auto', marginBottom: '6px' }}>Gaming Case Compatible</h3> */}
      <div style={spacerStyle}></div>
      <TwoColumnForFavorites />

    </div>
  )
}

export default PageAllFavorites; 