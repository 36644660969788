import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import gameData from "./data/gameData_en-US.json";
// import gameData from "/data/gameData_en-US.json";
// const gameData = require('../public/data/gameData_en-US.json');
import removeButtonImg from './images/btn_favorite_focus@2x.png'; // 導入返回按鈕圖像

function SliderFavorite() {
    const [images, setImages] = useState([]);
    const [savedFavoriteGames, setSavedFavoriteGames] = useState('');
    const [gameData, setGameData] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const systemLanguage = localStorage.getItem('language');

                var response = await fetch("./data/gameData_" + systemLanguage + ".json");

                let data; // 在 try 塊外部聲明一個變量
                try {
                    data = await response.json();
                    // 在這裡處理獲取到的 JSON 格式數據
                    console.log("成功獲取到 JSON 格式的數據:", data);
                } catch (error) {
                    // JSON 格式轉換失敗，處理錯誤情況
                    response = await fetch("./data/gameData_en-US.json");
                    data = await response.json();
                    console.error("獲取 JSON 格式數據失敗:", error);
                }

                const editorsChoose = data.GamingCaseCompatible;
                const extractedImages = editorsChoose.map(item => ({
                    bigImageUrl: item.bigImageUrl,
                    imageUrl: item.imageUrl,
                    title: item.title, // 遊戲名稱
                    description: item.description,
                    appStoreLink: item.appStoreLink, // App Store 鏈接
                    gameId: item.id // 遊戲 ID
                }));

                const savedFavoriteGames = localStorage.getItem('favoriteGames');

                if (savedFavoriteGames != null) {
                    const savedGamesArray = savedFavoriteGames.split(',');

                    // 過濾出 savedFavoriteGames 中存在的遊戲
                    const filteredImages = extractedImages.filter(image => savedGamesArray.includes(image.gameId));
                    setImages(filteredImages);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, [savedFavoriteGames]);


    const orientation = window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal';
    const slidesToShow = orientation === 'vertical' ? Math.min(3, images.length) : Math.min(5, images.length);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow + 1,
        // slidesToShow: images.length < 3 ? images.length : 3,
        // slidesToScroll: images.length < 3 ? images.length + 1 : 3,
        variableWidth: true, // 根據圖片的寬度自適應
        arrows: 0 // 確保箭頭顯示

    };
    const arrowStyles = `
    .slick-prev:before, .slick-next:before {
      color: blue; // 設置箭頭顏色
    }
  `;
    const handleImageClick = (appStoreLink, gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面
        // history.push(appStoreLink);
        // localStorage.setItem('favoriteGames', '');
        var savedFavoriteGames = localStorage.getItem('favoriteGames');

        if ((savedFavoriteGames == null) || (savedFavoriteGames == '')) {
            localStorage.setItem('favoriteGames', gameId);
        } else {
            // localStorage.setItem('favoriteGames', savedFavoriteGames + "," + gameId);
            var favoriteGamesArray = savedFavoriteGames.split(',');

            // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
            if (!favoriteGamesArray.includes(gameId)) {
                // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
                favoriteGamesArray.push(gameId);
                // 更新本地存儲
                localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));

            }
        }
        savedFavoriteGames = localStorage.getItem('favoriteGames');

        setSavedFavoriteGames(savedFavoriteGames);
        // 如果您使用原生的網頁導航，可以使用 window.location.href
        window.location.href = appStoreLink;
    };

    const removeFavorite = (gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面
        // history.push(appStoreLink);
        // localStorage.setItem('favoriteGames', '');
        var savedFavoriteGames = localStorage.getItem('favoriteGames');


        var favoriteGamesArray = savedFavoriteGames.split(',');

        // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
        if (!favoriteGamesArray.includes(gameId)) {
            // // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
            // favoriteGamesArray.push(gameId);
            // // 更新本地存儲
            // localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));

        } else {
            //如果存在則刪除

            favoriteGamesArray = favoriteGamesArray.filter(item => item !== gameId);
            // 更新本地存儲
            localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
        }


        savedFavoriteGames = localStorage.getItem('favoriteGames');

        setSavedFavoriteGames(savedFavoriteGames);

        // window.location.reload();

    };
    return (
        <div className="slider-container" style={{ width: '100%', margin: '0 auto' }}>
            <style>{arrowStyles}</style>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                        <img
                            src={image.imageUrl}
                            alt={`Slide ${index}`}
                            // style={{ width: '124px', height: '124px', objectFit: 'cover', paddingLeft: '2px', paddingRight: '2px' }}
                            style={{
                                width: '106px', height: '106px', objectFit: 'cover', margin: '4px', borderRadius: '24px',
                                boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.6)'
                            }}
                            onClick={() => handleImageClick(image.appStoreLink, image.gameId)}
                        />
                        <img
                            src={removeButtonImg} // 添加可點擊的圖片
                            alt="Remove" // 圖片的替代文本
                            style={{
                                position: 'relative',
                                top: '-110px',
                                left: '81px',
                                width: '30px',
                                height: '30px',
                                cursor: 'pointer',
                                zIndex: '2',
                            }}
                            onClick={() => removeFavorite(image.gameId)} // 點擊事件處理函數
                        />
                        <div style={{ textAlign: 'center', width: '106px', height: '20px', color: '#fff', fontSize: '14px', marginTop: '-26px' }}>{image.title}</div>

                    </div>
                ))}
            </Slider>


        </div>
    );
}

export default SliderFavorite;
